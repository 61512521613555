.elite-members-only-announcements-banner {
  .transition("top background", .7s, ease-in-out);
  background: #030b0f;
  height: 70px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 6400;
  top: -20vh;
  justify-content: center;
  display: flex;
  align-items: center;

  &:before {
    background: url(~img/components/cta-signup-banner/cta-signup-banner-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
    height: 70px;
    opacity: 0.8;
  }

  &:hover {
    text-decoration: none;
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    &:hover {
      span {
        &.red:last-child {
          text-decoration: underline;
        }
      }
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;

      font-size: calc(16px + 6 * ((100vw - 320px)/ 1330));
      color: #fff;
      padding: 0;
      font-family: @fontHeading;
      position: relative;
      font-weight: 500;

      &.red {
        color: #e83237;
        position: relative;
        font-weight: 700;
      }
    }
  }

  &__close {
    transform: translateY(-50%);
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: calc(22px + 6 * ((100vw - 320px)/ 1330));
    line-height: 1;
    padding: 1%;
    position: absolute;
    right: 2%;
    top: 50%;
    z-index: 6500;
  }
}

// Mobile support
@media (max-width: @widthMobileMax) {
  .elite-members-only-announcements-banner {
    &__content {
      flex-direction: column;
      gap: 0;

      .hide-mobile {
        display: none;
      }
    }
  }
}

